@import '../mixins/responsive';

.hide {
	display: none;

	&-sm {
		@include responsive(sm) {
			display: none;
		}
	}

	&-md {
		@include responsive(md) {
			display: none;
		}
	}

	&-lg {
		@include responsive(lg) {
			display: none;
		}
	}
}