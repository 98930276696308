.lightbox {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	min-height: 100vh;
	height: 100%;

	// transition
	transition: all 0.2s ease-in-out;
	overflow: hidden;
	pointer-events: none;
	user-select: none;
	opacity: 0;


	&.active {
		overflow: auto;
		pointer-events: initial;
		user-select: initial;
		opacity: 1;
		z-index: 9;
	}


	&__overlay {
		width: 100%;
		min-height: 100vh;
		height: 100%;
		background-color: rgba(255,255,255,.7)
	}

	&__container {
		position: absolute;
		top: 0;
		width: 35%;
		height: auto;
		background-color: #2A2A36;
		padding: 15px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}	

	&__content {
		padding: 15px 0;
	}

	&__title {
		color: #fff;
		text-transform: uppercase;
		text-align: center;
		padding-bottom: 10px;
		border-bottom: 1px solid #fff;
	}

	&__close {
		position: absolute;
		right: 15px;
		top: 15px;
		background: none;
		border: none;
		outline: none;
		box-shadow: none;
		cursor: pointer;
	}
}