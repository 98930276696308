.device {
	display: flex;
	flex-wrap: wrap;
	margin-top: 20px;
	flex: 0 1 100%;
	max-height: 365px;
	overflow: auto;


	&::-webkit-scrollbar-track
	{
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
		border-radius: 10px;
		background-color: #F5F5F5;
	}

	&::-webkit-scrollbar
	{
		width: 5px;
		background-color: #F5F5F5;
	}

	&::-webkit-scrollbar-thumb
	{
		border-radius: 4px;
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
		background-color: #555;
	}

	.no-content {
		width: 100%;
		color: #000;
	}

	&__item {
		position: relative;
		margin-right: 15px;
		margin-bottom: 15px;
		background-color: #2A2A36;
		border-radius: 3px;
		width: 165px;
		height: 300px;
		padding: 25px;
		text-align: center;
	}

	&__group {
		text-align: left;
		margin: 10px 0;
		label {
			display: block;
			font-size: 14px;
			color: #ccc;
			font-weight: bold;
			text-transform: uppercase;
			margin-bottom: 5px;
			color: #A7A7A7;
		}

		span {
			color: #fff;
			font-size: 12px;
		    font-weight: bold;
		}
	}

	&__count {

		&::after {
			content: attr(data-count);
			vertical-align: middle;
			text-align: center;
			width: 25px;
			height: 25px;
			display: inline-block;
			line-height: 25px;
			font-size: 12px;
			height: 25px;
			border-radius: 50px;
			margin-left: 10px;
			color: #fff;
			background-color: #EE1C23;
		}
	}

	&__delete {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: 10px;
		background-color: #3D3D4D;
		padding: 12px 40px;
		text-align: center;
		margin: 10px auto;
		border-radius: 3px;
		color: #fff;
		border: none;
		text-transform: uppercase;
		cursor: pointer;

		transition: all 0.2s ease-in-out;

		&:hover {
			background: #fff;
			color: #3D3D4D;
		}
	}
}