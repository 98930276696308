.tooltip {
	position: relative;
	cursor: pointer;

	&::before {
		top: 100%;
		left: 50%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}
	
	&::after {
		content: attr(data-tooltip);
		font-style: normal;
		font-weight: bold;
		position: absolute;
		z-index: 24;
		top: -30px;
		left: 0px;
		font-size: 10px;
		width: 100px;
		padding: 5px;
		text-align: center;
		border-radius: 3px;
		background-color: #3D3D4D;

		// transition
		width: 0;
		overflow: hidden;
		height: 0;
		opacity: 0;
		pointer-events: none;
		user-select: none;
		transition: opacity 0.2s linear;
	}

	&::before {
		position: absolute;
		z-index: 24;
		top: -6px;
		border-color: rgba(194, 225, 245, 0);
		border-top-color: #403f4e;
		border-width: 5px;

		// transition
		overflow: hidden;
		height: 0;
		opacity: 0;
		pointer-events: none;
		user-select: none;
	}


	&:hover {
		&::after {
			// transition
			width: auto;
			overflow: auto;
			height: auto;
			opacity: 1;
			pointer-events: initial;
			user-select: initial;
		}

		&::before {
			// transition
			overflow: auto;
			height: auto;
			opacity: 1;
			pointer-events: initial;
			user-select: initial;
		}
	}

	&.tooltip-large {
		&:hover {
			&::after {
				width: 100px;
			}
		}
	}
}

.tooltip-balloon-wrapper {
	position: absolute;
	overflow: visible;

	&::before{
		text-align:center;
		padding: 0.5em;
		float: left;
		width: 100%;
		height: 100%;
		border-radius: 50%;
	}

	.tooltip-balloon {
		display: none;
		position: absolute;
		width: max-content;
		min-height: max-content;
		background: #353547;
		color: #ffffff;
		box-shadow: 0 2px 50px 10px #1e1e24;
		z-index: 2;
	}

	&.tooltip-left .tooltip-balloon {
		margin-left: -6em;
		margin-top: 2.5em;
	}

	&.hover .tooltip-balloon{
		display: initial;
	}

	.tooltip-balloon:before {
		content:"";
		width: 0;
		height: 0;
		position: absolute;
	}

	&.tooltip-left .tooltip-balloon:before {
		left: 2em;
		top: -12px;
		border-bottom: 1em solid #353547;
		border-left: 0.5em solid transparent;
		border-right: 0.5em solid transparent;
		border-width: 12px;
	}

	.tooltip-balloon .tooltip-balloon-title {
		direction: rtl;
		margin-bottom: 15px;
	}

	.tooltip-balloon .tooltip-balloon-body {
		padding: 0.6em 0 0 1em;	
		margin: 0px 62px 0 8px;
		font-size: 12px;
	}

	.tooltip-balloon .tooltip-balloon-body:last-child {
		margin-bottom: 16px;
	}
}
