@import './mixins/responsive';

.login {
	display: flex;
	align-items: center;
	justify-content: center;
	background-image: url('../../images/background_login.png');
	background-repeat: no-repeat;
	background-size: cover;
	height: 100vh;

	@include responsive(sm) {
		flex-direction: column;
	}

	&__logo {
		position: relative;
		margin-right: 20%;

		@include responsive(sm) {
			margin-right: 0;

			svg {
				width: 200px;
			}
		}
	}

	&__box {
		width: 430px;
		height: 375px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		background-color: #fff;
		box-shadow: 15px 15px 15px rgba(0, 0, 0, 0.25);

		@include responsive(sm) {
			width: calc(100% - 30px);
		}
	}
	
	&__form {
		position: relative;
		width: 270px;
		margin: 40px 0 20px;

		.msg {
			&::after {
				display: none;
			}
		}
	}

	&__input {
		display: block;
		width: 100%;
		height: 42px;
		border: 1px solid #000;
		margin-bottom: 20px;
		box-sizing: border-box;
		padding-left: 10px;

		&::placeholder {
			color: #000;
		}
	}

	&__submit {
		background: #000;
		width: 100%;
		cursor: pointer;
		border-radius: 3px;
		border: none;
		font-weight: bold;
		padding: 13px 0;
		color: #fff;
		font-size: 14px;
	}
}