.details {
	&__count {
		&::after {
			content: attr(data-count);
			vertical-align: middle;
			text-align: center;
			width: 25px;
			height: 25px;
			display: inline-block;
			line-height: 25px;
			font-size: 12px;
			height: 25px;
			border-radius: 50px;
			margin-left: 10px;
			color: #fff;
			background-color: #EE1C23;
		}
	}

	&__content {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
		max-height: 400px;
		overflow: auto;

		&::-webkit-scrollbar-track
		{
			-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
			border-radius: 10px;
			background-color: #F5F5F5;
		}

		&::-webkit-scrollbar
		{
			width: 5px;
			background-color: #F5F5F5;
		}

		&::-webkit-scrollbar-thumb
		{
			border-radius: 4px;
			-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
			background-color: #555;
		}
	}

	.session__item {
		flex: 0 1 calc(50% - 30px);
		margin: 10px 0;
		border-radius: 3px;
		padding: 10px;
		background-color: #2A2A36;

		label {
			display: block;
			font-size: 9px;
			color: #ccc;
			font-weight: bold;
			text-transform: uppercase;
			margin-bottom: 5px;
			color: #A7A7A7;
		}

		span {
			font-size: 11px;
		    font-weight: bold;
		}

		&-header {
			cursor: pointer;
			position: relative;
			display: flex;
			justify-content: flex-start;
			align-items: center;

			&::after {
				content: '+';
				font-weight: bold;
				font-size: 20px;
				position: absolute;
				right: 10px;
				top: 5px;
			}

			&.active {
				&::after {
					content: '-'
				}
			}
		}
		
		&-content {
			display: flex;
			justify-content: flex-start;

			// transition
			overflow: hidden;
			margin: 0;
			pointer-events: none;
			user-select: none;
			height: 0;
			opacity: 0;
			transition: all 0.2s ease-in-out;

			&.active {
				overflow: initial;
				opacity: 1;
				pointer-events: initial;
				user-select: initial;
				height: auto;
				margin-top: 20px;
			}
		}

		&-contentleft {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			flex: 0 0 33%;
			border-right: 2px solid #fff;
			padding-right: 20px;

			.session__group {
				margin-bottom: 15px;
			}
		}

		&-contentright {
			padding-left: 20px;
			label {
				display: inline-block;
				font-size: 9px;
				color: #ccc;
				font-weight: bold;
				text-transform: uppercase;
				margin-bottom: 5px;
				color: #A7A7A7;
			}
		}
	}

	.session__group {
		margin-right: 15px;

		&-profile {
			b {
				display: none;
			}
		}

		&-name {
			position: relative;
		    flex: 0 0 33%;
			border-right: 2px solid #fff;
			padding-right: 20px;

			span {
				display: block;
			    max-width: 150px;
			}
		}

		&-connectiontype {
			.icon {
				img {
					max-width: 20px;
				}
			}
		}
	}
}