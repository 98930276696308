.user {
	&__header {
		display: flex;
		justify-content: space-around;
		align-items: center;
		background-color: #ccc;
		padding: 20px;
		border-radius: 3px;
		font-weight: bold;
		text-transform: uppercase;
	}

	&__count {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;
		padding: 0 6%;
	
		&-title {
			color: #fff;
			font-weight: bold;
			display: inline-block;
			vertical-align: middle;
			text-transform: uppercase;

			&::after {
				content: attr(data-count);
				vertical-align: top;
				text-align: center;
				width: 25px;
				height: 25px;
				display: inline-block;
				line-height: 25px;
				font-size: 12px;
				height: 25px;
				border-radius: 50px;
				margin-left: 10px;
				color: #fff;
				background-color: #EE1C23;
			}
		}
	}

	&__group {
		text-align: center;
		flex: 0 1 30%;
	}

	&__list {
		padding: 20px 0;
	}
	&__item {
		display: flex;
		align-items: center;
		justify-content: space-around;
		color: #fff;
		font-weight: bold;
		margin-bottom: 15px;
		padding-bottom: 15px;
		border-bottom: 1px solid rgba(255,255,255,.1);
	}

	&__button {
		cursor: pointer;
		padding: 10px;
		border-radius: 3px;
		border: none;
		outline: none;
		box-shadow: none;
		margin-right: 10px;
		transition: all 0.2s ease-in-out;

		&:hover {
			background-color: #fff;
			color: #3D3D4D;

			svg {
				stroke: #3D3D4D;
			}
		}

		&--add {
			text-transform: uppercase;
			font-weight: bold;
			background: #3D3D4D;
			color: #fff;
		}

		&--exclude {
			background: #EE1C23;
		}
		&--edit {
			background: #3D3D4D;
		}
	}
}