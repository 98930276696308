.box-itens-tvod {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-around;
	align-items: center;
	.movie-tvod{
		background: #3D3D4D;
		color: #FFF;
		position: relative;
		width: 19%;
		margin-bottom: 1%;
		border-radius: 5px;
		overflow: hidden;
		.movie-tvod-category{
			position: absolute;
			font-size: 10px;
			background: black;
			padding: 3px 10px 4px;
			text-transform: uppercase;
			right: 0px;
			top: 0px;
		}
		.movie-tvod-name{
			height: 75px;
			flex-flow: column wrap;
			justify-content: center;
			align-items: center;
			display: flex;
			margin-top: 21px;
			box-sizing: border-box;
			padding: 0px 15px;
			text-align: center;
		}
		.movie-tvod-price{
			width: 100%;
			padding: 7px 7px 8px;
			text-align: center;
			background: #1a1a21;
			box-sizing: border-box;
			font-size: 15px;
			font-weight: 700;
		}
		.movie-tvod-dates{
			box-sizing: border-box;
			padding: 10px;
			.movie-tvod-dates-row{
				font-size: 13px;
				border-bottom: 1px dotted rgba(255, 255, 255, 0.3);
				padding-bottom: 8px;
				margin-bottom: 8px;
				&:last-child{
					padding-bottom: 0px;
					margin-bottom: 0px;
					border-bottom: 0px;
				}
				strong{
					width: 65px;
					display: inline-block;
					text-align: right;
					margin-right: 10px;
				}
			}
		}
		.movie-tvod-status{
			width: 100%;
			padding: 7px 7px 8px;
			text-align: center;
			background: #1a1a21;
			box-sizing: border-box;
			font-weight: 700;
			text-transform: uppercase;
			font-size: 12px;

			&.Ativo{
				background: #0a961c;
			}

			&.Expirado{
				background: #ad5800;
			}

			&.Cancelado{
				background: #ad0000;
			}
		}
		.movie-tvod-details{
			width: 100%;
			padding: 7px 7px 8px;
			text-align: center;
			background: #191921;
			box-sizing: border-box;
			font-weight: 700;
			text-transform: uppercase;
			font-size: 12px;
			cursor: pointer;
			-webkit-transition: all 0.3s ease-in-out;
			-moz-transition: all 0.3s ease-in-out;
			-o-transition: all 0.3s ease-in-out;
			transition: all 0.3s ease-in-out;
			
			&:hover{
				background: #000;
			}

			&.movie-tvod-details-disable{
				opacity: 0.4;
				pointer-events: none;
			}
		}
	}
}

.modal-tvod{
	position: fixed;
    width: 100%;
    height: 100vh;
    top: 0px;
    left: 0px;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
	.modal-tvod-content{
		width: 600px;
		height: 90%;
		background: #3D3D4D;
		position: relative;
		color: #FFF;
		padding: 20px;
		box-sizing: border-box;
		.modal-tvod-close{
			font-size: 20px;
			position: absolute;
			top: -25px;
			right: -40px;
			color: #FFF;
			font-family: Arial;
			padding: 10px;
			cursor: pointer;
		}
		.modal-tvod-title{
			font-size: 18px;
			margin-bottom: 10px;
			padding-bottom: 10px;
			border-bottom: 1px solid #FFF;
		}
		.modal-tvod-itens{
			overflow: hidden;
			overflow-y: auto;
			max-height: calc(100% - 47px);
			padding-bottom: 20px;
			box-sizing: border-box;
			.session__item {
				flex: 0 1 calc(50% - 30px);
				margin: 10px 0;
				border-radius: 3px;
				padding: 10px;
				background-color: #2A2A36;
		
				label {
					display: block;
					font-size: 9px;
					color: #ccc;
					font-weight: bold;
					text-transform: uppercase;
					margin-bottom: 5px;
					color: #A7A7A7;
					margin-right: 6px;
				}
		
				span {
					font-size: 11px;
					font-weight: bold;
				}
		
				&-header {
					cursor: pointer;
					position: relative;
					display: flex;
					justify-content: flex-start;
					align-items: center;
		
					&::after {
						content: '+';
						font-weight: bold;
						font-size: 20px;
						position: absolute;
						right: 10px;
						top: 5px;
					}
		
					&.active {
						&::after {
							content: '-'
						}
					}
				}
				
				&-content {
					display: flex;
					justify-content: flex-start;
		
					// transition
					overflow: hidden;
					margin: 0;
					pointer-events: none;
					user-select: none;
					height: 0;
					opacity: 0;
					transition: all 0.2s ease-in-out;
		
					&.active {
						overflow: initial;
						opacity: 1;
						pointer-events: initial;
						user-select: initial;
						height: auto;
						margin-top: 20px;
					}
				}
		
				&-contentleft {
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;
					flex: 0 0 33%;
					border-right: 2px solid #fff;
					padding-right: 20px;
		
					.session__group {
						margin-bottom: 15px;
					}
				}
		
				&-contentright {
					padding-left: 20px;
					label {
						display: inline-block;
						font-size: 9px;
						color: #ccc;
						font-weight: bold;
						text-transform: uppercase;
						margin-bottom: 5px;
						color: #A7A7A7;
					}
				}
			}
		
			.session__group {
				margin-right: 15px;
		
				&-profile {
					b {
						display: none;
					}
				}
		
				&-name {
					position: relative;
					flex: 0 0 33%;
					border-right: 2px solid #fff;
					padding-right: 20px;
		
					span {
						display: block;
						max-width: 150px;
					}
				}
		
				&-connectiontype {
					.icon {
						img {
							max-width: 20px;
						}
					}
				}
			}
		}
	}
}