* {
  padding: 0;
  margin: 0;
}

// fonts
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

// utils
@import './utils/hide.scss';
@import './utils/global.scss';
@import './utils/error.scss';
@import './utils/tooltip.scss';

// components
@import './components/msg.scss';
@import './components/tabs.scss';
@import './components/percent.scss';