.tabs {
	&__header {
		
	}
	&__icon {
		position: absolute;
		top: 17px;
		left: 10px;
		pointer-events: none;

		svg {
			width: 25px;
    		height: 22px;
		}
	}

	&__button {
		position: relative;
		margin-right: 10px;
		border-radius: 3px;
		color: #fff;
		padding: 20px 40px;
		background-color: #3D3D4D;
		border: none;
		text-transform: uppercase;
		font-weight: bold;
		font-size: 14px;
		box-shadow: none;
		outline: none;
		cursor: pointer;
		transition: background-color 0.5s linear;

		&:active, &:focus {
			border: none;
			box-shadow: none;
		}

		span {
			pointer-events: none;
		}

		&.active {
			position: relative;
			background-color: #EE1C23;

			&:after, &:before {
				top: 100%;
				left: 50%;
				border: solid transparent;
				content: " ";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
			}

			&:after {
				border-color: rgba(136, 183, 213, 0);
				border-top-color: #EE1C23;
				border-width: 10px;
				margin-left: -10px;
			}
			&:before {
				border-color: rgba(194, 225, 245, 0);
				border-top-color: #EE1C23;
				border-width: 10px;
				margin-left: -10px;
			}
		}
	}

	&__content {
		margin: 40px 0;

		> div {
			overflow: hidden;
			opacity: 0;
			height: 0;
			pointer-events: none;
			user-select: none;
			transition: opacity 0.2s ease-in-out;

			&.active {
				overflow: initial;
				pointer-events: initial;
				user-select: initial;
				opacity: 1;
				height: auto;
			}
		}

		.no-content {
			background-color: #fff;
			padding: 20px;
		}
	}
}