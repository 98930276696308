.ingresso {
	display: flex;
	flex-direction: column;
	background-color: #3D3D4D;
	padding: 20px;

	&__title {
		color: #fff;
		padding: 10px;
	}
	
	&__count {
		color: #fff;
		display: inline-block;
		vertical-align: middle;
		margin-bottom: 20px;

		&::after {
			content: attr(data-count);
			vertical-align: middle;
			text-align: center;
			width: 25px;
			height: 25px;
			display: inline-block;
			line-height: 25px;
			font-size: 12px;
			height: 25px;
			border-radius: 50px;
			margin-left: 10px;
			color: #fff;
			background-color: #EE1C23;
		}
	}

	label {
		display: inline-block;
		font-size: 11px;
		color: #ccc;
		font-weight: bold;
		text-transform: uppercase;
		color: #A7A7A7;
		margin-left: 10px;
	}

	&__item {
		background-color: #2A2A36;
		border-radius: 3px;
		padding: 15px;
		margin-bottom: 15px;
		

		&-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			color: #fff;
		}

		&-content {
			border-top: 1px solid #ccc;
			

			.ingresso__group {
				margin: 0 0 15px;

				span, p {
					color: #fff;
					white-space: pre-wrap;
					display: block;
				    overflow: hidden;
				}
			}

			// transition
			overflow: hidden;
			margin: 0;
			pointer-events: none;
			user-select: none;
			height: 0;
			opacity: 0;
			transition: all 0.2s ease-in-out;

			&.active {
				overflow: initial;
				opacity: 1;
				pointer-events: initial;
				user-select: initial;
				height: auto;
				padding-top: 20px;
				margin-top: 20px;
			}
		}
	}

	&__button {
		background: #03363D;
		text-transform: uppercase;
		border-radius: 3px;
		padding: 10px 20px;
		text-decoration: none;
		font-weight: bold;
		color: #fff;
	}

	&__input {
		&-group {
		margin-top: 10px;
    	background-color: #2A2A36;
    	padding: 15px;
		}
		&-group:empty {
			display: none;
		}
	}

	&__group {

		&-subject {
			overflow: hidden;
		    max-width: 300px;
		    span {
		    	white-space: nowrap;
		    }
		}
	}

	&__mensagens {
		color: #fff;
		font-size: 12px;

		&-item {
			margin-bottom: 10px;  
		}
	}

	&__checked {
		color: #FFF;
	}

	&__unchecked {
		color: #FFF;
	}

	&__enable{
		margin-top: 10px;
	}
}