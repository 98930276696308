.status{
    display: flex;
	flex-wrap: wrap;
	margin-top: 10px;
	flex: 0 1 100%;
	max-height: 365px;
	overflow: auto;

    &__indicator{
        width: 6px;
        height: 6px;
        margin: 7px 11px 16px 0;
        border-radius: 3px;
        vertical-align: middle;	
        float: left;

        &-active{
            background-color: #02ff1a;
        }

        &-processing{
            background-color: #ffe302;
        }
    }

    &__info{
        font-family: open-sans, sans-serif;
        font-weight: 300;
    }
    
    &__historic{
        display: contents;
        font-size: 14px;
        text-decoration: underline;
        cursor: pointer;
    }

    &__description{
        width: 500px;
        display: inline-block;

        span{
            margin-right: 3px;
        }
    }

    &__button{
        width: fit-content;
    }

    &__delete {
		
		bottom: 10px;
		text-align: center;
		border-radius: 3px;

		color: #fff;
        font-size: 11px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
		border: none;
		text-transform: uppercase;
		cursor: pointer;

        margin: 18px 0 0;
        padding: 10px 33px 11px;
        background-color: #252531;

		transition: all 0.2s ease-in-out;

		&:hover {
			background: #fff;
			color: #3D3D4D;
		}
	}

    &__button {
		&-details {
			outline: none;
			box-shadow: none;
			cursor: pointer;
			border-radius: 3px;
			padding: 15px 40px;
			background: #2A2A36;
			border: none;
			color: #fff;
			font-weight: bold;
			font-size: 14px;
			text-transform: uppercase;
			transition: all 0.2s ease-in-out;

			&:hover {
				background: #ffffff69;
				color: #2A2A36;
			}
		}

		&-close {
			@extend .status__button-details;
            padding: 4px 15px;
            font-size: 10px;
            position: absolute;
            background: none;

			.icon {
				font-style: normal;
				> svg {width: 10px;}
			}
		}
	}
}