.degustacao{
    display: flex;
	flex-wrap: wrap;
	flex: 0 1 100%;
	max-height: 365px;
	overflow: auto;

	&-title {
		margin-bottom: 10px;
	} 

	&__content{
		margin-top: 30px;
	}

	&__info{
        font-family: open-sans, sans-serif;
        font-weight: 300;
    }
	
    &__button{
        width: fit-content;
    }

	&__indicator{
        width: 6px;
        height: 6px;
        margin: 7px 11px 16px 0;
        border-radius: 3px;
        vertical-align: middle;	
        float: left;

        &-active{
            background-color: #02ff1a;
        }

        &-invalid{
            background-color: #ff0202;
        }
    }

    &__reset {
		
		bottom: 10px;
		text-align: center;
		border-radius: 3px;

		color: #fff;
        font-size: 11px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
		border: none;
		text-transform: uppercase;
		cursor: pointer;

        margin: 18px 0 0;
        padding: 10px 33px 11px;
        background-color: #252531;

		transition: all 0.2s ease-in-out;

		&:hover {
			background: #fff;
			color: #3D3D4D;
		}
	}

}