.error {
	position: relative;

	&::after {
		position: absolute;
		content: attr(data-count);
		top: -5px;
		right: -15px;
		vertical-align: middle;
		text-align: center;
		width: 17px;
		height: 17px;
		line-height: 17px;
		font-size: 10px;
		border-radius: 50px;
		color: #fff;
		background-color: #393944;
	}


	&-icon {
		position: relative;
		cursor: pointer;
	}
}