.dashboard {
	width: 100%;
	min-height: 100vh;
	height: 100%;
	background-color: #2A2A36;

	&__content {
		padding: 60px 0;
	}
}

// component header
.header {
	background: #fff;
	box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);

	&__container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 100px;
	}

	&__search {
		flex: 0 1 40%;
	}

	&__welcome {
		.welcome__title {
			font-weight: bold;
		}
	}

	&__button {
		&--dashboard {
			color: #fff;
			text-decoration: none;
			cursor: pointer;
			padding: 10px;
			border-radius: 3px;
			background: #2A2A36;
			color: #fff;
			border: none;
			text-transform: uppercase;
			font-weight: bold;
			font-size: 13.33px;
		}

		&--newticket {
			border: none;
			background: #30aabc;
			color: #102305;
			padding: 10px;
			text-decoration: none;
			text-transform: uppercase;
			font-size: 13.33px;
			border-radius: 3px;
			cursor: pointer;
			outline: none;
		}
	}
}

// component search
.search {
	width: 100%;

	&__label {
		display: block;
	}

	&__group {
		position: relative;
	}

	&__label {
		font-size: 8px;
		font-weight: bold;
		text-transform: uppercase;
		margin-bottom: 5px;
	    line-height: 25px;
		margin-top: -25px;
	}

	&__input {
		width: 100%;
		border-radius: 5px;
		height: 40px;
		border: none;
		background-color: #F6F6F6;
		box-sizing: border-box;
		padding-left: 20px;

		&:focus, &:active {
			border: none;
			outline: none;
			box-shadow: none;
		}

		&::placeholder {
			color: #3D3D4D;
			font-size: 12px;
			font-weight: bold;
			text-transform: uppercase;
		}

		&::-webkit-search-decoration, &::-webkit-search-cancel-button, &::-webkit-search-results-button {
			-webkit-appearance:none;
		}
	}

	&__button {
		background: none;
		border: none;
		position: absolute;
		right: 10px;
		top: 10px;
	}
}

// component logout
.logout {
	&__button {
		cursor: pointer;
		padding: 10px;
		border-radius: 3px;
		background: #EE1C23;
		color: #fff;
		border: none;
		text-transform: uppercase;
		font-weight: bold;
	}
}

// Component Ticket
.new_ticket {

	&__group {
		margin-bottom: 10px;
	}

	input[type="text"],select, textarea {
		width: 100%;
		height: 40px;
		border: none;
		border-radius: 3px;
		box-sizing: border-box;
		padding-left: 10px;
	}

	textarea {
		height: 100px;
		padding: 10px;
	}

	button[type="submit"] {
		cursor: pointer;
		box-shadow: none;
		padding: 10px;
		border-radius: 3px;
		background: #3D3D4D;
		color: #fff;
		border: none;
		float: right;
		text-transform: uppercase;
		font-weight: bold;
	}
}