@import '../mixins/responsive';

@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    50% {
		background-position: 200px 0;
    }

    100%{
        background-position: 468px 0
    }
}


body {
	font-family: 'Open Sans';
	.loading {
		opacity: 0;
		pointer-events: none;
		height: 0;
		overflow: hidden;

		transition: opacity 0.2s ease-in-out;
	}
	&.loading {
		.loading {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			min-height: 100vh;
			height: 100%;
			background-color: rgba(0,0,0,.7);
			z-index: 24;
	
			// transition
			opacity: 1;
			pointer-events: initial;
			overflow: initial;

			transition: opacity 0.2s ease-in-out;

			&__gif {
				width: 100px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			&__bars {
				display: flex;
				flex-direction: column;
				div {
					border-radius: 3px;
					background-color: #3D3D4D;
					margin-bottom: 40px;
					width: 100%;
					height: 126px;
					animation-duration: 1s;
				    animation-fill-mode: forwards;
				    animation-iteration-count: infinite;
				    animation-name: placeHolderShimmer;
				    animation-timing-function: linear;
				    background: #f6f7f8;
				    background: linear-gradient(to left, #3D3D4D  20%, #5e5e79 18%, #3D3D4D  33%);
				}
			}
		}
	}


	&::-webkit-scrollbar-track
	{
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
		border-radius: 10px;
		background-color: #F5F5F5;
	}

	&::-webkit-scrollbar
	{
		width: 5px;
		background-color: #F5F5F5;
	}

	&::-webkit-scrollbar-thumb
	{
		border-radius: 4px;
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
		background-color: #555;
	}
}

.container {
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;

	@include responsive(sm) {
		max-width: calc(100% - 20px);
	}
}
