.admin {
	width: 100%;
	min-height: 100vh;
	height: 100%;
	background-color: #2A2A36;

	&__content {
		padding: 60px 0;
	}
	

	.header {
		&__welcome {    flex: 0 1 70%; }
		&__button {
			&--dashboard {
				color: #fff;
				text-decoration: none;
				cursor: pointer;
				padding: 10px;
				border-radius: 3px;
				background: #2A2A36;
				color: #fff;
				border: none;
				text-transform: uppercase;
				font-weight: bold;
				font-size: 13.33px;
			}
		}
	}
	
	// lightbox new user
	#new_user, #edit_user {
		.form__group {
			width: 100%;
			margin: 10px 0;

			label {
				text-transform: uppercase;
				color: #fff;
				line-height: 35px;
				font-weight: bold;
			}
	
			input[type="text"],input[type="password"] {
				width: 100%;
				height: 40px;
				border: none;
				border-radius: 3px;
				box-sizing: border-box;
				padding-left: 10px;
			}
			
			.input__label {
				display: flex;
				align-items: center;
				justify-content: space-between;
				color: #fff;
				a {font-size: 12px; cursor: pointer;} 
			}

			&--footer {
				margin: 20px 0 0 0;
				text-align: right;
			}

			&--double {
				display: flex;
				justify-content: flex-end;
				align-items: center;
					
				label {display: block;}

				.input__group {
					width: calc(55% - 20px);
					text-align: left;

					&--checkbox {
						display: flex;
						align-items: center;
						justify-content: center;
						
						select {
							height: 40px;
    						border-radius: 5px;
							margin-top: 34px;
							margin-right: 30px;
							font-weight: bold;
						}
						label {
							line-height: 17px;
    						height: 5px;
    						width: 75px;
						}
					}
				}
			}
			
			button {
				cursor: pointer;
				box-shadow: none;
				padding: 10px;
				border-radius: 3px;
				background: #3D3D4D;
				color: #fff;
				border: none;
				text-transform: uppercase;
				font-weight: bold;

				&:focus, &:active {
					border: none;
					outline: none;
				}
			}
		}
	}

	.alert {
		display: block;
		text-align: center;
		padding: 10px;
		margin: 20px 0 0;
		border-radius: 3px;
		background-color: #ff122b;
		color: #fff;
		font-weight: bold;
	}
}