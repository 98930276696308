.contas {
	&__item {
		position: relative;
		color: #fff;
		padding: 40px 20px;
		border-radius: 3px;
		margin-bottom: 40px;
		background-color: #3D3D4D;
		display: flex;
		justify-content: space-between;
	    flex-wrap: wrap;
	}
	&__group {

		label {
			display: block;
			text-transform: uppercase;
			color: #A7A7A7;
			font-weight: bold;
		}
	}
	&__group--email {
	}
	&__group--operator {
	}
	&__group--urn {
	}
	&__group--action {
	}

	&__cancelamento {
		&-title {
			margin: 10px 0;
		}
	}

	&__button {
		&-details {
			outline: none;
			box-shadow: none;
			cursor: pointer;
			border-radius: 3px;
			padding: 15px 40px;
			background: #2A2A36;
			border: none;
			color: #fff;
			font-weight: bold;
			font-size: 14px;
			text-transform: uppercase;
			transition: all 0.2s ease-in-out;

			&:hover {
				background: #fff;
				color: #2A2A36;
			}
		}

		&-editemail {
			@extend .contas__button-details;
			padding: 1px 5px;
		    font-size: 10px;
		    float: right;
	    	transition: all 0.2s linear;

		    .icon {

		    	svg {
		    		width: 12px;
		    	}
		    }

		    &:hover {
		    	background: #fff;

		    	svg {
		    		stroke: #2a2a36;
		    	}
		    }
		}

		&-editstatus {
			@extend .contas__button-details;
			padding: 1px 5px;
			font-size: 10px;
			margin-left: 10px;
			transition: all 0.2s linear;

		    .icon {

		    	svg {
		    		width: 12px;
		    	}
		    }

		    &:hover {
		    	background: #fff;

		    	svg {
		    		stroke: #2a2a36;
		    	}
		    }
		}

		&-invalidate {
			@extend .contas__button-details;
			padding: 8px 15px;
			font-size: 10px;
			position: absolute;
			background: none;
			left: 0;
			top: 0;

			.icon {
				font-style: normal;
				> svg {width: 10px;}
			}
		}
	}

	&__device, &__details, &__profile, &__salesforce, &__status, &__degustacao {
		flex: 0 1 100%;
		height: 0;
		overflow: hidden;
		pointer-events: none;
		user-select: none;
		transition: all 0.2s ease-in-out;

		&.active {
			margin-top: 30px;
			user-select: initial;
			height: auto;
			overflow: hidden;
			pointer-events: initial;
		}
	}

	&__details {
		&.active {
			
		}
	}

	&__profile {
		&-title {
			margin-bottom: 10px;
		} 

		.perfis__item-type {
			display: inline-block;
			min-width: 100px;
			position: relative;
		    font-size: 10px;

    		&::after {
			    content: '-';
			    position: absolute;
				right: 0;
			    display: inline-block;
			    margin: 0 10px;
    		}
		}

	}

	&__updateEmail {

		&-group {
			width: 100%;
			margin: 10px 0;

			label {
				display: block;
				margin-bottom: 10px;
				color: #fff;
			}
		}
		
		&-input {
			width: 100%;
			height: 35px;
			border: none;
			border-radius: 3px;
			padding-left: 10px;
			box-sizing: border-box;
		}

		&-input:disabled {
			width: 100%;
			height: 35px;
			border: none;
			border-radius: 3px;
			padding-left: 10px;
			box-sizing: border-box;
			background: #3d3d4d;
		}
		
		&-button {
			@extend .contas__button-details;
			margin: 15px 0 0 0;
			background: #3d3d4d;
			float: right;
			transition: all 0.5s linear;

			&:hover {
				background: #fff;
				color: #3d3d4d;
			}
		}
	}

	&__updateStatus {
		&-date{
			&-hide{
				display: none;
				
				width: 100%;
				margin: 10px 0;

				label {
					margin-bottom: 10px;
					color: #fff;
				}
			}

			&-show{
				display: block;
				
				width: 100%;
				margin: 10px 0;

				label {
					margin-bottom: 10px;
					color: #fff;
				}
			}

		}

		&-group {
			width: 100%;
			margin: 10px 0;

			label {
				display: block;
				margin-bottom: 10px;
				color: #fff;
			}
		}
		
		&-input {
			width: 100%;
			height: 35px;
			border: none;
			border-radius: 3px;
			padding-left: 10px;
			box-sizing: border-box;
		}
		
		&-input:disabled {
			width: 100%;
			height: 35px;
			border: none;
			border-radius: 3px;
			padding-left: 10px;
			box-sizing: border-box;
			background: #3d3d4d;
		}

		&-button {
			@extend .contas__button-details;
			margin: 15px 0 0 0;
			background: #3d3d4d;
			float: right;
			transition: all 0.5s linear;

			&:hover {
				background: #fff;
				color: #3d3d4d;
			}
		}
	}

	&__salesforce {
		&-list {
		    display: flex;
    		justify-content: flex-start;
    		list-style: none;
		}
		
		&-item {
			display: flex;
		    background-color: #2a2a35;
		    padding: 20px;
		    flex: 0 1 calc(100%);
		    margin-left: 10px;

		    &:first-child {
		    	margin-left: 0;
		    }
		}

		&-agroup {
			flex: 0 1 30%;
			padding: 0 10px;
		}

		&-itemgroup {
			margin-bottom: 5px;
			> label {
				display: block;
			    font-size: 14px;
			    color: #ccc;
			    font-weight: bold;
			    text-transform: uppercase;
			    margin-bottom: 5px;
			    color: #A7A7A7;
			}

			> span {
				color: #fff;
    			font-size: 12px;
    			font-weight: bold;
			}

			&--dadossf {
				margin: 10px 0;
			}

			&--cancelamento {
				border-left: 1px solid #fff;
				border-right: 1px solid #fff;
				margin: 10px 0;
				padding: 0 10px;

				.contas__cancelamento-title {
					text-align: center;
				}
			}

			&--suspensao {
				margin: 10px 0;

				.contas__suspensao-title {
					text-align: center;
					margin: 10px 0;
				}
			}
		}

		&-title {
			margin: 10px 0;
		} 
	}
}
