.percent {
	position: absolute;
	right: 5px;
	top: 28px;
	
	&::after {
		left: 50px;
	}

	&:hover {
		&::after {
			top: -30px;
    		left: 50px;
		}

		&::before {
			top: -15px;
		    left: 40px;
    		transform: rotate(90deg);
		}
	}

	.progress {
	    position: absolute;   
	    height: 50px;
	    width: 50px;
	    cursor: pointer;
	    top: 50%;
	    left: 50%;
	    margin: -80px 0 0 -80px;
	}

	.progress-circle {
	  	transform: rotate(-90deg);
		margin-top: -40px;
	}

	.progress-circle-back {
		fill: none; 
		stroke: #EE1C23;
		stroke-width:3px;
	}

	.progress-circle-prog {
		fill: none; 
		stroke: green;
		stroke-width: 3px;  
		stroke-dasharray: 0 999;    
		stroke-dashoffset: 0px;
	    transition: stroke-dasharray 0.7s linear 0s;
	}

	.progress-text {
		width: 100%;
		position: absolute;
		top: -14px;
		text-align: center;
		font-size: 0.5em;
	}
}