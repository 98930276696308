.msg {
	position: absolute;
	width: calc(100% - 25px);
	border: 1px solid red;
	padding: 10px;
	margin: 15px 0;
	font-size: 10px;
	border-radius: 3px;
	color: #fff;
	font-weight: bold;
	text-align: center;

	&.error {
		background-color: #f21d2e;
	}

	&.success {
		background-color: green;
	}
}