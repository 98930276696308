@import './mixins/responsive';

.welcome {
	background-color: #2A2A36;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	
	@include responsive(sm) {
		flex-direction: column;
		justify-content: space-around;
		background-color: unset;
	}

	@include responsive(md) {
    	flex-direction: column;
	}


	&__header {
		margin-right: 20%;

		@include responsive(sm) {
			margin-right: 0;
			text-align: center;
		}

		@include responsive(md) {
			margin-right: 0;
		}
	}


	&__body {
		display: flex;
		justify-content: space-between;
		align-items: center;
		text-align: center;

		@include responsive(md) {
			flex-direction: column;

			svg {
				width: 200px;
			}
		}

		@include responsive(sm) {
			flex-direction: column;
		}
	}

	&__logo {
		@include responsive(md) {
			margin-bottom: 50px;
		}

		@include responsive(sm) {
		 	svg {
		 		fill: red;
		 		width: 200px;

		 		g { 
		 			path {
		 				fill: #2A2A36;
		 			}
		 		}
		 	}
		}

		&-telecine {
			img {
				@include responsive(sm) {
					width: 50px;
				}
			}
		}
	}
	&__access {
		width: 430px;
		height: 375px;
		display: flex;
		justify-content: space-around;
		flex-direction: column;
		align-items: center;
		background-color: #fff;
		box-shadow: 15px 15px 15px rgba(0, 0, 0, 0.25);

		@include responsive(sm) {
			width: 100%;
			max-width: 100%;
			height: initial;
			box-shadow: none;
			justify-content: center;
		}

		&-button {
			background-color: #2A2A36;
			border: none;
			padding: 10px 60px;
			border-radius: 3px;
			color: #fff;
			font-weight: bold;
			text-decoration: none;

			@include responsive(sm) {
				padding: 20px 80px;
			}
		}
	}
}