$break-small-min: 320px;

$break-medium-min: 768px;

$break-large: 1024px;

@mixin responsive($media) {
  @if $media == sm {
    @media only screen and (min-width: $break-small-min) and (max-width: $break-medium-min - 1) { @content; }
  }
  @else if $media == md {
    @media only screen and (min-width: $break-medium-min) and (max-width: $break-large - 1) { @content; }
  }
  @else if $media == lg {
    @media only screen and (min-width: $break-large) { @content; }
  }
}